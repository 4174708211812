
body {
  margin: 0;
  font-family: "Saira Condensed", sans-serif !important; /* Default font */
  font-weight: 600 !important; /* Ensures regular weight */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(13, 13, 13), rgb(11, 2, 13));
}
@font-face {
  font-family: 'Saira Condensed';
  src: url('https://fonts.gstatic.com/s/sairacondensed/v8/Ww7nu_0KfA5iF2ePxFkhGboBGkmTch5yQF1R8H-Ts7Q.woff2') format('woff2');
  font-display: swap;
}

h1, h2, h3, h4, h5, h6, nav a {
  font-family: "Tilt Neon", cursive !important; /* Tilt Neon for headings and navbar links */
  font-weight: bold !important;
}

nav a {
  text-decoration: none; /* Optional: Ensure consistent link styling */
}

h1{
  color: #ffa600;
}

p{
  color:#dff3ef;
}