.App {
  text-align: center;
}


.App-header {
  background-color: #ffa11d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(9px + 2vmin);
  color: white;
}

.App-link {
  color: #ffa622;
}

